
:root {
    --border-radius: 10px;
    --primary-color: #1A2534;
    --secondary-color: #2E3A4D;
    --tertiary-color: #91a8cb;
    --cuaternary-color: #233245;
}

html,
body,
#root {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

body {
    height: 100vh;

}

/* Chart JS */

.canvasjs-chart-canvas {
    border-radius: var(--border-radius) !important;
}

.canvasjs-chart-toolbar button {
    margin: 5px 20px 0 0;
}

/* Barra de scroll */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: var(--secondary-color); 
}

::-webkit-scrollbar-thumb {
    background-color: var(--tertiary-color); 
    border-radius: 6px; 
}

/* Bootstrap Buttons */

button {
    background-color: var(--cuaternary-color) !important;
    border-color: var(--cuaternary-color) !important;
}

.dropdown-menu.show {
    background-color: var(--cuaternary-color) !important;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid var(--tertiary-color);
}

